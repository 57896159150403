/* PC NAV */
@media only screen and (min-width: 960px) {

  .headWrapper {
    @mixin flexSpBetweenLeftStart;

  }

  .navigation {
    width:100%; 
    nav {
      & > ul {
        font-size:1.5rem;
        background-color:var(--white_color);
        @mixin flexStartRight;

        & > li {
          text-align:center;
          position:relative;
          & > a {
            display:block;
            padding:30px 40px;
            position:relative;
            &.subMenu {
              &::before,&::after {
                content:'';
                position:absolute;
                width:7px;
                height:2px;
                right:20px;
                top:0;
                bottom:0;
                margin:auto 0;
                border:1px solid color(var(--white_color) lightness(-30%));
                border-left-color:transparent;
                border-right-color:transparent;
                border-top-color:transparent;
                transform:rotate(45deg);
              }
              &::after {
                transform:rotate(-45deg) !important;
                right:16px;
                bottom:1px;
              }
              &:hover {
                & + ul {
                  max-height:433px;
                }
              }
            }
          }
          & > ul {
            position:absolute;
            z-index:99;
            left:0;
            top:84px;
            max-height:0;
            transition:top 0.3s;
            overflow:hidden;
            transition:max-height 0.7s;
            min-width:100%;
            &:hover { max-height:433px;}
            & > li {
              border-bottom:1px solid color(var(--white_color) lightness(-10%));
              white-space: nowrap;
              text-align:left;
              min-width:100%;
              background-color: color(var(--main_color) lightness(-10%));
              transition:background-color 0.5s;
              &:hover {
                background-color:color(var(--main_color) lightness(10%));
              }
              &:last-child { border:none;}
              a {
                color:var(--white_color);
                display:block;
                min-width:100%;
                padding:15px 40px;
                position:relative;
                &::before,&::after {
                    content:'';
                    position:absolute;
                    top:0;
                    bottom:0;
                    margin:auto 0;
                }
                &::after {
                    right:12px;
                    width:0;
                    height:0;
                    border:5px solid transparent;
                    border-left-color:color(var(--accent_color) lightness(30%)) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
