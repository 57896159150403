@define-mixin left7pxArrow {
  position:relative;
  padding-left:15px;
  display:block;
  &::before {
    content:'';
    position:absolute;
    border-top:1px solid var(--main_color);
    border-right:1px solid var(--main_color);
    transform: rotate(45deg);
    width:7px;
    height:7px;
    left:0;
    top:0;
    bottom:0;
    margin:auto 0;
  }
}