
@define-mixin flexSpBetWeen {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@define-mixin flexSpBetWeen_re {
  display:flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}



@define-mixin flexSpBetweenLeftStart {
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}


@define-mixin flexSpAround {
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around ;
}

@define-mixin flexSpCenter {
  display:flex;
  flex-wrap: wrap;
  justify-content:center ;
}

@define-mixin flexSpStartLeftStart {
  display:flex;
  justify-content: start;
  flex-direction: row;
  align-items: flex-start;
}

@define-mixin flexStartLeft {
  display:flex;
  flex-wrap: wrap;
  justify-content: start;
}

@define-mixin flexStartRight {
  display:flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@define-mixin flexSpBetWeenStart {
  display:flex;
  flex-wrap: wrap;
  align-items:start;
  justify-content: space-between;
}

@define-mixin flexSpBetWeenCenter {
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content: space-between;
}


@define-mixin flexSpAround {
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around ;
}

@define-mixin flexSpCenter {
  display:flex;
  flex-wrap: wrap;
  justify-content:center ;
}
/**  flex layout **/
