/** PC box center **/
@define-mixin pcBoxCenter {
  margin:0 auto;
  @media only screen and (min-width: 1601px) { width:1170px; }
  @media only screen and (min-width: 1501px) and (max-width: 1600px) { width:75%; }
  @media only screen and (min-width: 1301px) and (max-width: 1500px) { width:80%; }
  @media only screen and (min-width: 1201px) and (max-width: 1300px) { width:90%; }
  @media only screen and (min-width: 960px) and (max-width: 1200px) { width:95%; }
}

@define-mixin mainBlockWidth {
  @media only screen and (min-width: 1601px) { width:70%; }
  @media only screen and (min-width: 1501px) and (max-width: 1600px) { width:72%; }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) { width:70%; }
  @media only screen and (min-width: 1200px) and (max-width: 1401px) { width:68%; }
  @media only screen and (min-width: 960px) and (max-width: 1201px) { width:67%; }
}


@define-mixin h2MainStyle {
  @media only screen and (min-width: 1600px) { font-size:3rem; }
  @media only screen and (min-width: 1200px) and (max-width:1599px) { font-size:2.5rem; }
  @media only screen and (min-width: 960px) and (max-width:1199px) { font-size:2.5rem; }
  @media only screen and (max-width:950px) { font-size:2.3rem;  display:block; text-align:center; }
  color:var(--main_color);
  font-weight:700;
  position:relative;
  letter-spacing:4px;
  &::before, &::after {
    content:'';
    position:absolute;
    left:0;
    right:0;
    margin:0 auto;
  }

  &::before {
    width:10%;
    height:2px;
    background:var(--main_color);
    bottom:-25px;
  }
  &::after {
    font-size:1rem;
    letter-spacing:1px;
    width:100%;
    bottom:-15px;
  }



}