@define-mixin spNaviButtonKIT {
  position:relative;
  span.spNaviButton  {
    @media only screen and (min-width: 960px) { display:none; }
    @media only screen and (max-width: 959px) {
      position:absolute;
      right:15px;
      top:20px;
      width:30px;
      height:21px;

      /* ケバブアイコン */
      /* 基本設定 */      
      &::before, &::after {
        content:'';
        position:absolute;
        height:3px;
        background-color:var(--white_color);
        transition:width 0.3s , transform 0.3s, top 0.3s;
      }

      /* 上線 */
      &::before {
        width:100%;
        top:0;
        left:0;
        right:0;
        margin:0 auto;
      }

      /* 下線 */
      &::after {
        width:30%;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
      }

      /* 中央線 */
      span.spline {
        display:block;
        position:absolute;
        width:60%;
        height:3px;
        background-color:var(--white_color);
        top:9px;
        left:0;
        right:0;
        margin:0 auto;
        transition:left 0.3s, opacity 0.5s;
        opacity:1;
      }
    }
  }

  /* メニュー開き時の閉じボタン */
  span.activeNavigation {
    @media only screen and (max-width: 959px) {
      
      &::before, &::after {
        width:90% !important;
        top:10px;
      }
      &::before {
        transform:rotate(45deg);
      }
      &::after {
        transform:rotate(-45deg);
      }

      span.spline {
        opacity:0;
        left:100%;
      }
    }
  }
}
