:root {
  --main_color : #0e4787;
  --accent_color : #cd4d4c;
  
  --serif_link_color : #ffc4ed;
  --white_color: #ffffff;
  --bg_color:#f1f1f1;
  --bg_color2:#f6f6f6;
  
  --default_border_color:lighten(--accent_color,30%);
  --default_btn_color:lighten(--accent_color,15%);
  
  /** sns color **/
  --color_twitter :#3D97F1;

  /* ranking color */
  --rank1_color : #fb0;
  --rank2_color : #aaa;
  --rank3_color : #d98;
  
}
