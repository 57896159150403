
@font-face { font-family: "Yu Gothic M"; src: local("Yu Gothic Medium"); }
@font-face { font-family: "Yu Gothic M"; src: local("Yu Gothic Bold"); font-weight: bold; }

a {
  color:var(--main_color);
  -webkit-transition: color 0.3s;

  &:hover 
  {
    color:var(--accent_color);
  }
}


/*--------------------------------------reset */
html {scroll-behavior: smooth;}
body { 
  background-color:var(--bg_color);
  color:color(var(--main_color) lightness(-15%));
  font-family: -apple-system, BlinkMacSystemFont, "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  @media only screen and (min-width: 960px) {
    padding-top:84px;
  }
  @media only screen and (max-width: 959px) {
    padding-top:64px;
  }

}
p { line-height:1.6;}

.fadeBox {
  .lazyload, .lazyloading {  
    opacity: 0;
    transition: opacity 400ms;
  }
  a.lazyloaded {
    opacity: 1;
  }
} 