

/**  about css **/
@define-mixin aboutSettings {
  /**  abou image class **/
  .index_aboutimg {
    border-radius:100px;
    margin-bottom:30px;
  }
  dl {
    dt {
      font-size:1.8rem;
    }
    dd {
      font-size:1.8rem;
      &:first-of-type {
        color:#b1b1b1;
        padding-bottom:30px;
      }
    }
  }
  i {
    transition:transform 0.5s, color;
    transform:scale(1.2);
    color:#757575;
    &:hover {
      transform:scale(2.2);
      color:#00aced;
    }
  }
}
