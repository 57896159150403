@import "./_spNavi";
@import "./_pcNavi";

@import "./_logo";

@import '../../lib/spnavbutton/_type002.css';
@import "./_header";

.fixedMainWrap {
    position:fixed;
    width:100%;
}

.scrollStop {
    overflow:hidden;
    height:100%;
    position: fixed;
}

.nav_size {
    i {
        width:20px;
        height:14px;
        color:rgba(0, 0, 0, 0.54);
        margin-left:0px;
        margin-right:5px;
    }
}

.navigation {
    font-weight:700;
}
  