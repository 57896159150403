@import './_pc.css';
@import './_sp.css';

#business {
  dl {
    &::before {
      content:'';
      position:absolute;
      top:30px;
      left:0;
      right:0;
      margin: 0 auto;
      width:50px;
      height:50px;
    }

    &.send {
      &::before {
        background:transparent url("/assets/images/svg/send.svg") no-repeat center top;
        background-size:cover;
      }
    }
    &.work {
      &::before {
        background:transparent url("/assets/images/svg/work.svg") no-repeat center top;
        background-size:cover;
      }
    }
    &.support {
      &::before {
        background:transparent url("/assets/images/svg/support.svg") no-repeat center top;
        background-size:cover;
        
      }
    }
  }
}




.lum-open {
  z-index:9999;
}