
.logo 
{
    display:block;
    margin:auto;
    @media only screen and (min-width: 960px) {
        width:300px;
        height:80px;
        padding:0 30px;
    }
    @media only screen and (max-width: 959px) {
        width:250px;
        height:60px;
        padding:0 15px;
    }
 }
.sitelogo_s {
    vertical-align: middle;
}
 