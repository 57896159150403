.navHead {
  display:block;
  background-color:var(--white_color);
  width:100%;
  position:fixed;
  z-index:8;
  top:0;
  left:0;
  @media only screen and (min-width: 960px) {
    box-shadow:0px 0px 10px -3px rgba(0, 0, 0, 0.1);
    height:84px;
  }
  @media only screen and (max-width: 959px) {
    box-shadow:0px 0px 10px -3px rgba(0, 0, 0, 0.5);
    height:64px;
    &::before {
      content:'';
      position:absolute;
      width:64px;
      height:64px;
      top:0;
      right:0;
      background: var(--main_color);
    }
  }
  .headWrapper {
    @mixin pcBoxCenter;

    @media only screen and (max-width: 959px) {
      h1 {
        display:inline-block;
        height:60px;
      }
      text-align:left;
    }

    @mixin spNaviButtonKIT;

  }
}

