/* SP NAV */
@media only screen and (max-width: 959px) {
    .navigation {
        /* .navigation */
        width:100%;
        position:absolute;
        top:60px;
        left:-100%;
        z-index:10;
        height:100vh; 
        font-size:1.5rem;
        backdrop-filter: blur(0px);
        background-color:rgba(255, 255, 255, 0.0);
        transition:all 0.3s ease-in;

        &.openNavi {
            overflow:scroll;
            left:0%;
            backdrop-filter: blur(5px);
            background-color:rgba(255, 255, 255, 0.9);
            nav {
                left:0% !important;
            }
        }

        nav {
            display:block;
            width:100%;
            height:100vh;
            position:relative;
            z-index:11;
            left:-100%;
            transition:left 0.3s ease-in-out;
            
            & > ul {
                height:100vh;
                a {
                    display:block;
                    padding-left:15px;
                    color:var(--main_color);
                    line-height:64px;
                    font-size:1.6rem;
                }
                & > li {
                    border-bottom:1px solid var(--main_color);
                    & > a {
                        font-weight:700;
                        @mixin left7pxArrow;

                        &.moreList {
                            &::after {
                                transform: rotate(0deg) !important;
                            }
                            & ~ ul {
                                max-height:1000px !important;
                            }
                        }

                        /* 汎用疑似要素 */
                        &::before,&::after {
                            content:'';
                            position:absolute;
                            top:0;
                            bottom:0;
                            margin:auto 0;
                            right:10px;
                            left:inherit;
                        }

                        &.singleMenu {
                            position:relative;
                            &::after {
                                right:12px;
                                width:0;
                                height:0;
                                border:5px solid transparent;
                                border-left-color:#fae7a5 !important;
                            }
                        }

                        &.subMenu {
                            position:relative;
                            &::before,&::after {
                                right:15px;
                                width:15px;
                                height:3px;
                                background-color:#fae7a5 !important;
                            }
                            &::after {
                                transform:rotate(90deg);
                                transition:transform 0.3s;
                            }
                        }
                    }
                    & > ul {
                        max-height:0px;
                        overflow:scroll;
                        transition:max-height 0.3s;
                        background:color(var(--main_color) lightness(-20%));
                        & > li {
                            border-bottom:1px solid rgba(255,255,255,0.3);
                            & > a {
                                color:var(--white_color);
                                line-height:44px;
                                position:relative;
                                &::before,&::after {
                                    content:'';
                                    position:absolute;
                                    top:0;
                                    bottom:0;
                                    margin:auto 0;
                                }
                                &::after {
                                    right:12px;
                                    width:0;
                                    height:0;
                                    border:5px solid transparent;
                                    border-left-color:#fae7a5 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
