@media only screen and (min-width: 960px) {

  body {
    background:transparent url('./assets/images/header/index_top.jpg') no-repeat center top;
    background-attachment: fixed;
    background-size: cover;
  }

  .heroWrap {

    .heroBlock {
      
      @mixin pcBoxCenter;
      align-items: center;
      padding:130px 50px;


      .seeThroughWhite {
        background:rgba(255,255,255,0.8);
        padding:20px;
        width:40%;
    
        dt {
          font-size:2rem;
          font-weight:bold;
          margin-bottom:30px;
          text-align:center;
        }
        dd {
          p {
            font-size:1.6rem;
            text-shadow: 1px 1px 0px var(--white_color);
          }
        }
    
        width:40%;
      }
      .slideVisualBlock {
        width:50%;
    
        .swiper-container {
          height:100%;
          .swiper-slide {
            img {
              height:100%;
              border:10px solid #fff !important;
            }
          }
        }
      }
    }
  }


  /*-----------------------*/

  .helloWrap {
    background-color:var(--white_color);
    padding:70px 0;
    h2 {
      text-align:center;
      @mixin h2MainStyle;
     &::after {
      content:'Message from the President';
     }
    }
    .helloBlock {
      @mixin pcBoxCenter;
      overflow:hidden;
      padding:100px 10px 10px 10px;
      img {
        float:right;
        width:30%;
        margin:0 0 15px 100px;
      }
      p {
        display: inline;
        font-size:1.6rem;
        &:nth-of-type(2) {
          display:block;
          margin-bottom:15px;
        }
        &.rightAlign {
          text-align:right;
          display:block;
          padding-top:20px;
        }
      }
    }
  }



  
  /*-----------------------*/

  .businessWrap {
    padding:70px 0;
    background:rgba(255,255,255,0.8);
    h2 {
      text-align:center;
      @mixin h2MainStyle;
     &::after {
      content:'Business Infomation';
     }
    }
    ul {
      @mixin flexSpBetWeen;
      @mixin pcBoxCenter;
      padding:100px 10px 10px 10px;
      li {
        width:31%;

        margin-bottom:70px;
        min-height:260px;
        background-size:95%;
        background-repeat:no-repeat;

        position:relative;

        &:nth-of-type(2) {
          dl {
            background:rgba(93,168,197,0.7);
          }
        }

        &:nth-of-type(3) {
          dl {
            background:rgba(14,71,135,0.7);
            dt {
              letter-spacing:1px !important;
            }
          }
        }


        dl {
          width:95%;
          min-height:260px;
          max-height:240px;
          padding-top:100px;
          background:rgba(255,113,112,0.7);
          position:absolute;
          left:5%;
          top:6%;
          color:var(--white_color);
          text-align:center;


          dt {
            font-size:2.0rem;
            font-weight:bold;
            padding:0 0 15px;
            letter-spacing:4px;
          }

          dd {
            font-size:1.6rem;
            padding:10px 30px;
          }
        }
      }
    }
  }



  /*-----------------------*/

  .skillProgramWrap {
    background-color:var(--white_color);
    padding:70px 0;

    h2 {
      text-align:center;
      @mixin h2MainStyle;
      &::after {
        content:'Techcnical Training Program';
      }
    }

    .imgWrap {
      @mixin pcBoxCenter;
      a {
        display:inline-block;
        padding:100px 10px 10px 10px;
      }
  
      p {
        text-align:center;
  
        color:#f00;
        font-size:1.2rem;
  
        span.sp { display:none;}
      }
        
    }


  }

  /*-----------------------*/
  .aboutWrap {
    padding-top:70px;
    background:rgba(255,255,255,0.9);
    h2 {
      text-align:center;
      @mixin h2MainStyle;
     &::after {
       content:'Company Infomation';
     }
    }
    .infoWrap {
      @mixin pcBoxCenter;
      padding:100px 20px 50px 20px;

      table {
        tbody {
          display:block;
          @mixin flexSpBetWeen;
          align-items: baseline;
        }
        tr {
          margin-bottom:30px;
          display:block;
          width:45%;
        }

        th {
          font-size:1.6rem;
          font-weight:700;
          min-width:100px;
          vertical-align: top;
          text-align:right;
          padding-right:20px;
          color:var(--main_color);
        }
        td {
          vertical-align: top;
          font-size:1.6rem;
          padding-right:20px;
          border-bottom:1px solid var(--main_color);
          width:100%;
        }
      }
    }
    iframe {
      width:100%;
      height:400px;
    }
  }
  /*-----------------------*/
  footer {
    .footerWrap {
      background:var(--main_color);
      p {
        color:var(--white_color);
        text-align:center;
        line-height:50px;
        font-size:1.2rem;
      }
    }

    #topScroll {
      display:none;
    }
  }

  /*-----------------------*/
  /*-----------------------*/
  /*　inner page */
  /*-----------------------*/

  .flowWrap {
    background:rgba(255,255,255,0.9);
    padding:70px 0;
    h2 {
      text-align:center;
      @mixin h2MainStyle;
     &::after {
       content:'Training Flow';
     }
    }


    .flowBlock {
      @mixin pcBoxCenter;

      padding:100px 20px 50px 20px;
      ol {
        width:50%;
        margin:0 auto;
        & > li {
          margin-bottom:100px;

          position:relative;
          &::before, &::after {
            content:'';
            position:absolute;
            left:0;
            right:0;
            margin:0 auto;
          }

          &::before {
            bottom:-45px;
            width:50px;
            height:25px;
            background-color:var(--accent_color);
          }

          &::after {
            bottom:-140px;
            width:0;
            height:0;
            border:50px solid transparent;
            border-top-color:var(--accent_color);
          }
          &:last-child {
            &::before, &::after {
              background-color:transparent !important;
              border:none !important;
            }
          }
          dl {
            dt {
              font-weight:700;
              font-size:2.2rem;
              position:relative;
              padding-left:15px;
              line-height:50px;
              height:50px;
              background:var(--main_color);
              color:var(--white_color);

            }
            dd {
              font-size:1.8rem;
              background: #e6e6e6;
              padding:15px;

              & > ul {
                border:3px solid color(var(--accent_color) lightness(75%));
                margin-bottom:5px;
                @media only screen and (min-width: 960px) { 
                  width:100%;
                  padding:15px !important;
                }
          
                @media only screen and (max-width: 959px) { 
                  padding:15px 15px 0 15px !important;
                }
          
                & > li {
                  font-size:1.5rem !important;
                  position:relative;
                  border-bottom:1px dashed var(--accent_color);
                  &:last-child { border-bottom:none; }
                  padding-left:40px;
                  line-height:30px;
                  &::before, &::after {
                    content:'';
                    height:18px;
                    width:3px;
                    display:block;
                    background:var(--main_color);
                    position:absolute;
                    left:23px;
                    transform:rotate(45deg);
                    top:7px;
                  }
                  &::before{
                    height:10px;
                    transform:rotate(-45deg);
                    left:15px;
                    top:13px;
                  }
                }
              }
          

            }
          }
        }
      }

    }
  }

}