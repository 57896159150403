/*reset*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, 
fieldset, input, textarea, p, blockquote, th, td{
    margin: 0;
    padding: 0;
}
html{
}
h1, h2, h3, h4, h5, h6{
    font-size: 100%;
    font-weight: 700;
}
ol, ul{
    list-style:none;
}
fieldset, img{
    border:0;
}
table{
    border-collapse: collapse;
    border-spacing:0;
}
caption, th{
    text-align: left;
}
address, caption, cite, code, dfn, em, strong, th, var{
    font-style: normal;
    font-weight: normal;
}
img {
   vertical-align: bottom;
}
html {
    font-size: 10px;
    font-size: 62.5%;
}
a {
    color: #000;
    text-decoration: none;
}

body {
    position:relative;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
}